<template>
    <section class="xbts-fees">
        <p>
            В экосистеме BitShares каждой операции присваивается индивидуальная комиссия. Эти комиссии могут меняться.
            Тем не менее, они определяются только одобрением держателей долей, поэтому каждый держатель основного актива
            BitShares (BTS) имеет право влиять на то, какими эти комиссии должны быть. Если держатели согласятся снизить
            некую комиссию и консенсус будет достигнут, комиссия будет автоматически снижена блокчейном. Изменения
            параметров блокчейна предлагаются членами комитета. Этих члены избираются держателями долей и улучшают
            показатели гибкости и реакции.
        </p>

        {{fees}}

        <table class="table table-striped table-borderless">
            <tbody>
            <tr>

            </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import {fee_grouping} from '@/config';

export default {
    name: "Fees",
    data() {
        return {
            fee_grouping: fee_grouping,
            ltm_required: [5, 7, 20, 21, 34]
        }
    },
    computed: {
        fees() {
            return this.$store.getters['app/fees'];
        }
    },
}
</script>

<style scoped>

</style>
